import { postSearchRouteReference } from 'modules/postSearch/routing/postSearch.routes';
import { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';

type MainPostSearchRouteData = {
  pq?: string;
  slug: string;
  filters?: SearchFiltersByName;
};

export function getPostSearchRoutePath(data: MainPostSearchRouteData) {
  const { slug, pq, filters } = data;

  const queryParams: Record<string, unknown> = { ...filters };
  if (typeof pq !== 'undefined') queryParams.pq = pq;

  return postSearchRouteReference.with({ slug, ...queryParams });
}
